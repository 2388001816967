import React from "react";
import "../../assets/styles/homepart/footer.css";

import { NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo.png";
import footerLogo from "../../assets/images/media_library/Group-1190.png";

const Footer = () => {
  const presentYear = new Date().getFullYear();
  return (
    <div className="footer-wrap">
      <div className="footer-main">
        <div>
          <img
            className="footer-logo"
            src={logoImg}
            alt="logo"
          />
          <img
            className="footer-logo2"
            src={footerLogo}
            alt="footerLogo"
          />
        </div>
        <div className="footer-content">
          <div className="footer-item infoTech">
            {presentYear} Trobits infotech private limited
          </div>
          <div className="footer-item">
            <NavLink to={"/home"}>Home</NavLink>
          </div>
          <div className="footer-item">
            <NavLink to={"/services"}>Services</NavLink>
          </div>
          <div className="footer-item">
            <NavLink to={"/company"}>Company</NavLink>
          </div>
          <div className="footer-item">
            <NavLink to={"/work"}>Work</NavLink>
          </div>
          <div className="footer-item">
            <NavLink to={"/contact"}>Contact</NavLink>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-text">Sitemap</div>
        <div className="footer-text">Terms Of Use</div>
        <div className="footer-text">Privacy Policy</div>
        <div className="footer-bottom-copyright">
          &copy;{presentYear} Troibits Infotech Private Limited -
          <div>All rights Reserved</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
