import "font-awesome/css/font-awesome.min.css";
import jQuery from "jquery";
import React from "react";
import {
  FaHome,
  FaLaptop,
  FaPhone,
  FaTools,
  FaWarehouse,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import logoImg from "../assets/images/logo.png";
import "../assets/styles/header.css";
import "../assets/styles/header.scss";

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "home",
      isPositionFixed: false,
      isCarousel: false,
      isnav: 0,
    };
    this.addActiveClass = this.addActiveClass.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (window.location.pathname === "/home") {
      this.setState({ active: "home" });
    } else if (window.location.pathname === "/") {
      this.setState({ active: "home" });
    } else if (window.location.pathname === "/services") {
      this.setState({ active: "services" });
    } else if (window.location.pathname === "/company") {
      this.setState({ active: "company" });
    } else if (window.location.pathname === "/work") {
      this.setState({ active: "work" });
    } else if (window.location.pathname === "/contact") {
      this.setState({ active: "contact" });
    }
  }

  componentDidUpdate() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    let isCarousel = null;
    if (window.innerWidth <= 880) isCarousel = true;
    else isCarousel = false;

    this.setState({ isCarousel });
  };

  handleScroll(event) {
    if (
      event.srcElement.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      jQuery("#Scroll").css({
        "background-color": "#0b0b0b",
        position: "fixed",
      });
      this.setState({ isPositionFixed: true });
    } else {
      jQuery("#Scroll").css({
        "background-color": "transparent",
        position: "absolute",
      });
      this.setState({ isPositionFixed: false });
    }
  }

  addActiveClass(e) {
    const clicked = e.target.id;
    this.setState({ active: clicked });
  }

  render() {
    const { isCarousel } = this.state;

    if (!isCarousel)
      return (
        <div
          id="Scroll"
          style={{ backgroundColor: "transparent" }}
          className={`header-wrap${this.state.isPositionFixed ? " fixed" : ""}`}
          onScroll={this.handleScroll}>
          <div className="header">
            <div className="left-logo">
              <a href="/">
                <img
                  className="logo-img"
                  src={logoImg}
                  style={{
                    marginLeft: "-20%",
                  }}
                  alt="logoImg"
                />
              </a>
              {/* <label className="logo-label">Troibits</label> */}
            </div>
            <div className="menu menuA">
              <NavLink
                id="home"
                className={`menuA-item ${
                  this.state.active === "home" ? " blue text-primary" : ""
                }`}
                onClick={this.addActiveClass}
                to="/home">
                Home
                <div
                  id="home"
                  className={`circle ${
                    this.state.active === "home" ? "" : " d-none"
                  }`}></div>
              </NavLink>
              <NavLink
                id="services"
                className={`menuA-item ${
                  this.state.active === "services" ? " blue text-primary" : ""
                }`}
                onClick={this.addActiveClass}
                to="/services">
                Services
                <div
                  id="services"
                  className={`circle ${
                    this.state.active === "services" ? "" : " d-none"
                  }`}></div>
              </NavLink>

              <NavLink
                id="company"
                className={`menuA-item ${
                  this.state.active === "company" ? " blue text-primary" : ""
                }`}
                onClick={this.addActiveClass}
                to="/company">
                Company
                <div
                  id="company"
                  className={`circle ${
                    this.state.active === "company" ? "" : " d-none"
                  }`}></div>
              </NavLink>
              <NavLink
                id="work"
                className={`menuA-item ${
                  this.state.active === "work" ? " blue text-primary" : ""
                }`}
                onClick={this.addActiveClass}
                to="/work">
                Work
                <div
                  id="work"
                  className={`circle ${
                    this.state.active === "work" ? "" : " d-none"
                  }`}></div>
              </NavLink>
              <NavLink
                id="contact"
                className={`menuA-item ${
                  this.state.active === "contact" ? " blue text-primary" : ""
                }`}
                onClick={this.addActiveClass}
                to="/contact">
                Contact
                <div
                  id="contact"
                  className={`circle ${
                    this.state.active === "contact" ? "" : " d-none"
                  }`}></div>
              </NavLink>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div className="menu-B">
          <div id="mySidebar" className="sidebar">
            <button
              type="button"
              className="closebtn"
              onClick={() => closeNav()}>
              ×
            </button>
            <div className="flex items-center justify-center">
              <img
                src={logoImg}
                style={{
                  width: "50%",
                  height: "50%",
                }}
                alt="logoImg"
              />
            </div>
            <div
              className="pt-10"
              style={{
                width: "fit-content",
                height: "50%",
                margin: "auto",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}>
              <NavLink
                className="flex text-white text-lg"
                to="/home"
                onClick={() => closeNav()}>
                <FaHome
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                  }}
                />
                HOME
              </NavLink>
              <NavLink
                className="flex text-white text-lg"
                to="/services"
                onClick={() => closeNav()}>
                <FaTools
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                  }}
                />
                SERVICES
              </NavLink>
              <NavLink
                className="flex text-white text-lg"
                to="/company"
                onClick={() => closeNav()}>
                <FaWarehouse
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                  }}
                />
                COMPANY
              </NavLink>
              <NavLink
                className="flex text-white text-lg"
                to="/work"
                onClick={() => closeNav()}>
                <FaLaptop
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                  }}
                />{" "}
                WORK
              </NavLink>
              <NavLink
                className="flex text-white text-lg"
                to="/contact"
                onClick={() => closeNav()}>
                <FaPhone
                  style={{
                    fontSize: "25px",
                    marginRight: "15px",
                  }}
                />
                CONTACT
              </NavLink>
            </div>
          </div>
          <div className="flex" id="main">
            <button type="button" className="openbtn" onClick={() => openNav()}>
              ☰
            </button>
            <img
              src={logoImg}
              style={{
                width: "100px",
                height: "100%",
                objectFit: "cover",
              }}
              alt="logoImg"
            />
          </div>
        </div>
      );

    function openNav() {
      document.getElementById("mySidebar").style.width = "100vw";
      document.getElementById("main").style.marginLeft = "300px";
    }

    function closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    }
  }
}

export default HomeHeader;
