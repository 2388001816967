import jQuery from "jquery";
import React from "react";
import Slider from "react-slick";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import ClouderaIcon from "../../assets/images/homeimages/cloudera.png";
import GoogleIcon from "../../assets/images/homeimages/google.png";
import MicrosoftIcon from "../../assets/images/homeimages/microsoft.png";
import OfficeBG from "../../assets/images/homeimages/office.jpg";
import OracleIcon from "../../assets/images/homeimages/oracle.png";
import SalesForceIcon from "../../assets/images/homeimages/salesforce.png";
import SocialIcon from "../../assets/images/homeimages/social.png";
import MainLogo from "../../assets/images/media_library/Group-1190.png";
import Notch from "../../assets/images/media_library/Group-454.png";
import TechLogo from "../../assets/images/media_library/Group-491@2x-min.png";
import Consultant from "../../assets/images/media_library/Group-969-1.png";
import Growth from "../../assets/images/media_library/Group-970-1.png";
import styles from "../../assets/styles/companypart/company.module.css";
import "../../assets/styles/header.css";
import "../../assets/styles/header.scss";
import "../../assets/styles/homepart/common.css";
import "../../assets/styles/homepart/yolo.css";
import ContactPage from "../ContactPart/ContactPage";
import Map from "../HomePart/Map";

class Company extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    window.scrollTo({
      top: 10,
      left: 10,
      behavior: "smooth",
    });
  }

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  render() {
    function display_sub_text(Class, number) {
      console.log(jQuery(Class).css("display"));
      if (jQuery(Class).css("display") === "block") {
        jQuery(Class).css("display", "none");
      } else {
        jQuery(Class).css("display", "block");
      }
      jQuery(".hello").css("color", "black");
      for (var i = 1; i < 9; i++) {
        if (i === number) {
          jQuery("#" + number).css({ color: "#0072ff" });
        }
        var item = "#subText" + i;
        if (item !== Class) {
          jQuery(item).css("display", "none");
          jQuery("#" + i).css({ color: "white" });
        }
      }
    }

    const partnerSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
      ],
    };

    const PartnerComponent = ({ teamName, logo }) => {
      return (
        <div
          style={{
            width: "100px",
            height: "100px",
            marginLeft: 15,
          }}>
          {logo}
          <p
            style={{
              marginTop: 25,
              fontWeight: 600,
              textAlign: "center",
            }}>
            {teamName}
          </p>
        </div>
      );
    };

    const partnerData = [
      {
        id: 1,
        partnerName: "Google",
        logo: (
          <img
            src={GoogleIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="GoogleIcon"
          />
        ),
      },
      {
        id: 2,
        partnerName: "AWS",
        logo: (
          <img
            src={SocialIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="SocialIcon"
          />
        ),
      },
      {
        id: 3,
        partnerName: "Oracle",
        logo: (
          <img
            src={OracleIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="OracleIcon"
          />
        ),
      },
      {
        id: 4,
        partnerName: "Salesforce",
        logo: (
          <img
            src={SalesForceIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="SalesForceIcon"
          />
        ),
      },
      {
        id: 5,
        partnerName: "Cloudera",
        logo: (
          <img
            src={ClouderaIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="ClouderaIcon"
          />
        ),
      },
      {
        id: 6,
        partnerName: "Microsoft",
        logo: (
          <img
            src={MicrosoftIcon}
            style={{
              margin: "auto",
              width: "50%",
              height: "50%",
            }}
            alt="MicrosoftIcon"
          />
        ),
      },
    ];

    return (
      <div className={styles.CompanyHead}>
        <div className={styles.CompanyHeading}>
          <img src={OfficeBG} className={styles.CompanyBG} alt="office-bg" />
          <div className={styles.Companyleft}>
            <strong>
              <h3>We are</h3>
              <h1>
                A <span style={{ color: "#0a5de9" }}>Creative </span>
                Company
              </h1>
              <h4
                style={{
                  marginTop: "25px",
                  marginBottom: "10px",
                  fontWeight: 600,
                }}>
                Delivering interactive experiences and creating new standards
              </h4>
              <p>
                Troibits Infotech is a leading app development company that
                specializes in creating intuitive, user-friendly smart apps to
                transform businesses. We stay ahead of the curve by keeping
                up-to-date with the latest trends and technologies, such as
                Internet of Things, business automation, content management
                systems (CMS), customer relationship management (CRM), point of
                sale systems (POS), search engine optimization (SEO), and
                marketing strategy. We take a collaborative and transparent
                approach to every project, working closely with our clients to
                understand their goals and challenges. With our attention to
                detail, professionalism, and commitment to excellence, we have
                earned a reputation as a trusted partner for businesses of all
                sizes. Let Troibits Infotech help you unlock the full potential
                of your business with our forte in design and app development,
                along with our comprehensive suite of services. Contact us today
                to learn more about how we can help you achieve your digital
                transformation goals.
              </p>
            </strong>
            <a href="/contact" style={{ textDecoration: "none" }}>
              <button className={styles.CompanyBtn1}>Let's Chat</button>
            </a>
          </div>
          <div className={styles.Companyright}>
            <h1 style={{ color: "#0a5de9" }}> What We Do</h1>
            <p>
              <a href="/services#startup">Startup Acceleration</a>
            </p>
            <p>
              <a href="/services#mobileDev">Mobile App Development</a>
            </p>
            <p>
              <a href="/services#webDev">Website Development</a>
            </p>
            <p>
              <a href="/services#enterprise">Enterprise App Development</a>
            </p>
            <p>
              <a href="/services#product">Product Design and Branding</a>
            </p>
            <p>
              <a href="/services#nextGen">Next Gen Technology</a>
            </p>
            <p>
              <a href="/services#growth">Growth Marketing</a>
            </p>
            <p>
              <a href="/services#software">Software Maintenance</a>
            </p>
          </div>
        </div>
        <div className={styles.Companymid}>
          <div className={styles.Companymid1}>
            <h1>What we stand for</h1>
            <h6>
              Troibits focuses on high quality customer experience, satisfaction
              and honesty. We have the
              <br />
              confidence in our ability to digitally transform our customer’s
              lives and business.
            </h6>
          </div>
          <div className={styles.Companymid2}>
            <div className={styles.Image}>
              <img
                src={Consultant}
                className="mx-auto"
                id={styles.image}
                alt="Consultant"
              />
              <h3>Right IT Consultants</h3>
              <h6 id={styles.image_description}>
                We focus on a broad range of activities, like competitive
                analysis, corporate strategy, operations management, our core
                value is to tell you what is right for your business.
              </h6>
            </div>
            <div className={styles.Image}>
              <img
                src={Growth}
                className="mx-auto"
                id={styles.image}
                alt="Growth"
              />
              <h3
                style={{
                  marginTop: "10px",
                }}>
                Growing Together
              </h3>
              <h6 id={styles.image_description}>
                We partner with like-minded businesses to help improve and drive
                efficiency in business processes, operations and believe in
                growing together.
              </h6>
            </div>

            <div className={styles.Image} style={{ paddingBottom: "10%" }}>
              <img
                src={Notch}
                className="mx-auto"
                id={styles.image}
                alt="Notch"
              />
              <h3>With Top-Notch Apps</h3>
              <h6 id={styles.image_description}>
                Our best in class mobile and web apps can transform your
                customer experience and service, apart from increasing your
                product sales and business visibility.
              </h6>
            </div>
          </div>
        </div>
        <div className={styles.Companymid3}>
          <div className={styles.BackgroundLogo}>
            <div className={styles.firstBox}></div>
            <div className={styles.CompanyBox}>
              <div className={styles.Data}>
                <img src={MainLogo} className="mx-auto" alt="MainLogo" />
                <h2 style={{ color: "#0a5de9", paddingBottom: "1%" }}>
                  Our Mission
                </h2>
                <h4>
                  "To empower businesses worldwide with cutting-edge technology
                  solutions and drive their growth through innovation,
                  collaboration, and exceptional service."
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.CompanyLast}>
          <div>
            <img src={TechLogo} className={styles.techLogo} alt="TechLogo" />
          </div>
          <div className={styles.Process}>
            <h1>Our Process</h1>
            <h6 id={styles.image_description}>
              From idea to product launch, growth and software maintenance. We
              are determined to provide IT support through complete life cycle
              of your business.
            </h6>
            <ul className={styles.Companyulist}>
              <li
                className={styles.Companylist}
                id="1"
                onMouseEnter={() => changing_hover("1")}
                onMouseLeave={() => remove_hover("1")}
                onClick={() => display_sub_text("#subText1", "1")}>
                IP Protection
                <div
                  className={styles.subText}
                  id="subText1"
                  style={{ display: "none" }}>
                  Protecting Your Unique Ideas
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="2"
                onMouseEnter={() => changing_hover("2")}
                onMouseLeave={() => remove_hover("2")}
                onClick={() => display_sub_text("#subText2", "2")}>
                Consultant And Research
                <div
                  className={styles.subText}
                  id="subText2"
                  style={{ display: "none" }}>
                  In-Depth Analysis and Insights
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="3"
                onMouseEnter={() => changing_hover("3")}
                onMouseLeave={() => remove_hover("3")}
                onClick={() => display_sub_text("#subText3", "3")}>
                Ideation And Strategic Planning
                <div
                  className={styles.subText}
                  id="subText3"
                  style={{ display: "none" }}>
                  Identifying Key Success Factors
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="4"
                onMouseEnter={() => changing_hover("4")}
                onMouseLeave={() => remove_hover("4")}
                onClick={() => display_sub_text("#subText4", "4")}>
                Product Design
                <div
                  className={styles.subText}
                  id="subText4"
                  style={{ display: "none" }}>
                  Innovative and User-Centered Design
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="5"
                onMouseEnter={() => changing_hover("5")}
                onMouseLeave={() => remove_hover("5")}
                onClick={() => display_sub_text("#subText5", "5")}>
                Agile Deveploment
                <div
                  className={styles.subText}
                  id="subText5"
                  style={{ display: "none" }}>
                  Efficient and Streamlined Workflow
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="6"
                onMouseEnter={() => changing_hover("6")}
                onMouseLeave={() => remove_hover("6")}
                onClick={() => display_sub_text("#subText6", "6")}>
                Testing and Quality Assurance
                <div
                  className={styles.subText}
                  id="subText6"
                  style={{ display: "none" }}>
                  Proactive Bug Detection and Fixing
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="7"
                onMouseEnter={() => changing_hover("7")}
                onMouseLeave={() => remove_hover("7")}
                onClick={() => display_sub_text("#subText7", "7")}>
                Product Launch
                <div
                  className={styles.subText}
                  id="subText7"
                  style={{ display: "none" }}>
                  Successful Go-to-Market Strategy
                </div>
              </li>
              <li
                className={styles.Companylist}
                id="8"
                onMouseEnter={() => changing_hover("8")}
                onMouseLeave={() => remove_hover("8")}
                onClick={() => display_sub_text("#subText8", "8")}>
                Growth and Maintenance
                <div
                  className={styles.subText}
                  id="subText8"
                  style={{ display: "none" }}>
                  Scalable and Sustainable Solutions
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className={styles.CompanyTeam}>
          <p
            style={{
              fontSize: "2em",
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "30px",
            }}>
            Meet The Team
          </p>
          <div
            style={{
              margin: "auto",
              width: window.innerWidth < 800 ? "80%" : "50%",
            }}>
            <p
              style={{
                marginBottom: "50px",
                textAlign: "center",
              }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              nisl ipsum, tincidunt a vulputate eu, molestie a eros. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. Donec nisl ipsum,
              tincidunt a vulputate eu, molestie a eros.
            </p>
          </div>

          <Yolo />
        </div> */}
        <div className={styles.Companymid3}>
          <div className={styles.Partners}>
            <p
              style={{
                fontSize: "2em",
                fontWeight: 600,
                textAlign: "center",
                marginBottom: "30px",
              }}>
              Our Partners
            </p>
            <div
              style={{
                margin: "auto",
                padding: "10px",
              }}>
              <p
                className="partnerText lg:mb-10"
                style={{
                  textAlign: "center",
                }}>
                Our trusted and strategic partnerships allow us to leverage the
                <br /> latest technologies and deliver innovative and
                high-quality solutions to our clients.
              </p>
            </div>
            <div className={styles.partnerSlide}>
              <Slider {...partnerSettings} className={styles.partnerSlider}>
                {partnerData.map((item) => {
                  const { id, partnerName, logo } = item;
                  return (
                    <PartnerComponent
                      key={id}
                      teamName={partnerName}
                      logo={logo}
                    />
                  );
                })}
              </Slider>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
              }}>
              <div
                className="notice top-tri tri-part"
                style={{
                  width: "70%",
                }}
              />
            </div>
          </div>
        </div>

        {/* Contact Page */}
        <div
          style={{
            marginTop: window.innerWidth < 800 ? "50px" : "",
          }}>
          <ContactPage />
        </div>

        <div id="map">
          <Map />
        </div>
      </div>
    );
    function changing_hover(Id) {
      var d = document.getElementById(Id);
      jQuery(d).css("color", "blue");
    }
    function remove_hover(Id) {
      var d = document.getElementById(Id);
      jQuery(d).css({ color: "White", transition: "1s" });
    }
  }
}

export default Company;
