import React from "react";
import Automative from "../../assets/images/AssetsTroibits/automotive.png";
import Retail from "../../assets/images/AssetsTroibits/ecommerce.png";
import Education from "../../assets/images/AssetsTroibits/education.png";
import Finance from "../../assets/images/AssetsTroibits/finance.png";
import Fitness from "../../assets/images/AssetsTroibits/fitness.png";
import Food from "../../assets/images/AssetsTroibits/food.png";
import HealthCare from "../../assets/images/AssetsTroibits/healthcare.png";
import Logistics from "../../assets/images/AssetsTroibits/logistics.png";
import Networking from "../../assets/images/AssetsTroibits/networking.png";
import Pharma from "../../assets/images/AssetsTroibits/pharma.png";
import Estate from "../../assets/images/AssetsTroibits/realestate.png";
import Energy from "../../assets/images/AssetsTroibits/utilities.png";
import "../../assets/styles/services/industry.css";

function Industry() {
  return (
    <div className="col indu">
      <div className="title-industry text-center">
        <div className="col">
          <h1>
            <strong>Industries We Serve</strong>
          </h1>
        </div>
        <div className="col-industry">
          <h3>
            Your search for end-to-end technology partner ends here. Our
            services address all of your business needs and processes,
            <br />
            enabling us to generate higher productivity, faster resolutions and
            reduced costs.
          </h3>
        </div>
      </div>
      <div className="list-items">
        <div className="item">
          <img
            src={HealthCare}
            alt="healthcare"
          />
          <span>HealthCare</span>
        </div>
        <div className="item">
          <img
            src={Fitness}
            alt="fitness"
          />
          <span>Fitness</span>
        </div>
        <div className="item">
          <img
            src={Pharma}
            alt="pharma"
          />
          <span>Pharma</span>
        </div>
        <div className="item">
          <img
            src={Retail}
            alt="Retail"
          />
          <span>E-Commerce</span>
        </div>
        <div className="item">
          <img
            src={Education}
            alt="education"
          />
          <span>Education</span>
        </div>
        <div className="item">
          <img
            src={Food}
            alt="food"
          />
          <span>Food</span>
        </div>
        <div className="item">
          <img
            src={Finance}
            alt="finance"
          />
          <span>Finance</span>
        </div>
        <div className="item">
          <img
            src={Logistics}
            alt="logistics"
          />
          <span>Logistics</span>
        </div>
        <div className="item">
          <img
            src={Networking}
            alt="networking"
          />
          <span>Networking</span>
        </div>
        <div className="item">
          <img
            src={Automative}
            alt="automotive"
          />
          <span>Automotive</span>
        </div>
        <div className="item">
          <img
            src={Estate}
            alt="estatico"
          />
          <span>Real Estate</span>
        </div>
        <div className="item">
          <img
            src={Energy}
            alt="Energy"
          />
          <span>Utilities</span>
        </div>
      </div>
    </div>
  );
}

export default Industry;
