import { useEffect, useState } from "react";
import GrowthIcon from "../../assets/images/AssetsTroibits/growth Marketing (1).png";
import EnterpriseIcon from "../../assets/images/serivces/enterprise.PNG";
import MaintenanceIcon from "../../assets/images/serivces/maintainance.PNG";
import MobileDevIcon from "../../assets/images/serivces/mobile.PNG";
import NextGenIcon from "../../assets/images/serivces/nextgen.PNG";
import ProductDesignIcon from "../../assets/images/serivces/product.PNG";
import StartUpIcon from "../../assets/images/serivces/startup.PNG";
import WebDevIcon from "../../assets/images/serivces/website.PNG";
import "../../assets/styles/services/offers.css";
import useGetWindowWidth from "../../hooks/useWindowSize";

const Offers = () => {
  const [activeTab, setActiveTab] = useState("startup");
  const offerTabArr = [
    {
      id: 1,
      tabLabel: "StartUp Accelerator",
      scrollID: "startup",
    },
    {
      id: 2,
      tabLabel: "Product Design",
      scrollID: "product",
    },
    {
      id: 3,
      tabLabel: "Mobile App Development",
      scrollID: "mobileDev",
    },
    {
      id: 4,
      tabLabel: "Web Development",
      scrollID: "webDev",
    },
    {
      id: 5,
      tabLabel: "Enterprise App Development",
      scrollID: "enterprise",
    },
    {
      id: 6,
      tabLabel: "Next Gen Tech",
      scrollID: "nextGen",
    },
    {
      id: 7,
      tabLabel: "Growth Marketing",
      scrollID: "growth",
    },
    {
      id: 8,
      tabLabel: "Software Maintenance",
      scrollID: "software",
    },
  ];

  const offerContentArr = [
    {
      id: 11,
      headerText: "StartUp Accelerator",
      description: `
        We put in place a strategic plan and create a roadmap for your
        business and properly research the market and the segment in which
        you plan to grow your business in. Strategic planning is one of
        the key services provided by Troibits Infotech. Our customers partner
        with us to plan critical steps for their startup business. Our
        continuous analysis of the market trends and dynamics keeps us
        alert to changes and developments in different market sectors.
      `,
      subLinks: [
        "Strategy Planning",
        "Pitch Deck",
        "B2B, B2C Marketing",
        "ROI Management",
      ],
      nickname: "startup",
    },
    {
      id: 22,
      headerText: `Product Design`,
      description: `
        Our well crafted designs and product branding builds the perfect
        opportunity to create an interest in your brand and attract your
        customers in the first impression. Our product design and app
        design features are all about providing value to your customers
        and linking their needs to product attributes. A good design
        defines the lifetime of the product and should exceed customer’s
        expectations. Troibits helps to create better products that
        compete on value, satisfy the customer and makes your business
        operations easy.
      `,
      subLinks: [
        "Logo and Corporate Identity",
        "Branding and Marketing Media",
        "Web and Mobile App Design",
        "Animation and Graphics",
      ],
      nickname: "product",
    },
    {
      id: 33,
      headerText: "Mobile App Development",
      description: `
        Smartphones are a big part of our lives and businesses now and
        have become very useful. With more than 60% share in the total web
        traffic worldwide, having a well developed and designed mobile app
        increases productivity and sales in your business. Smart Apps
        developed by Troibits and digitization of your business provides
        value to your customers, builds your brand, shares exclusive
        content and strengthens customer loyalty.
      `,
      subLinks: [
        "Android App Development",
        "iOS App Development",
        "Hybrid App Development",
        "Flutter and SaaS",
      ],
      nickname: "mobileDev",
    },
    {
      id: 44,
      headerText: "Web App Development",
      description: `
        Your business needs an attractive and appealing website to
        increase traffic, build your digital identity and lead to high
        conversion rates. Getting a responsive marketing ready and SEO
        friendly website not only brings mobile users onboard but also
        increases brand visibility on multiple platforms. We make sure
        your website is developed with interactive design, attracting
        customers in the first glance, reflecting your brand and your
        business idea in the best way possible.
      `,
      subLinks: [
        "React, Angular and Vue Js",
        "Wordpress and Shopify",
        "CMS and Admin Web Apps",
        "Marketing Ready Websites",
      ],
      nickname: "webDev",
    },
    {
      id: 55,
      headerText: "Enterprise App Development",
      description: `
        With the increasing impact of Internet of Things and advanced
        mobile devices, businesses are going overboard developing
        enterprise apps to improve productivity and efficiency. We help you transform your business and help it
        evolve for future growth by developing custom real-time enterprise
        app systems providing simpler management of information, improved
        teamwork , simple and easy to use interfaces for your team.
      `,
      subLinks: [
        "CMS, CRM and POS Systems",
        "Sales and Inventory Management",
        "Logistics and Team Management",
        "E-Commerce and SaaS Systems",
      ],
      nickname: "enterprise",
    },
    {
      id: 66,
      headerText: "Next Gen Technology",
      description: `
        We are emerging into the next generation technological advancement
        for better business insights, boost efficiency and allow
        businesses to manage entire operations with our smart apps. With
        connected hardware and AI systems we can identify patterns,
        behaviour and gain much better insights into business operations.
        By connecting key processes we help businesses in boosting
        efficiency, productivity, asset tracking and smarted business
        models.
      `,
      subLinks: [
        "AI, AR, VR and ML",
        "I.O.T and Connected Hardware",
        "Intelligent Automation",
        "Robotics and Drone Tech",
      ],
      nickname: "nextGen",
    },
    {
      id: 77,
      headerText: "Growth Marketing",
      description: `
        Innovative and smart growth marketing holds the key to attracting
        many clients, customers and generating leads. With the digital era
        and rapid growth of online activities, your business needs a
        productive and cost effective digital transformation to capitalise
        and resonate with the young generation and traditional businesses.
        Troibits helps you successfully thrives your business by creating 
        tons of daily traffic with our growth marketing services and in 
        converting leads to sales.
      `,
      subLinks: [
        "Digital Marketing Campaigns",
        "Media and Video Production",
        "Social Media Marketing",
        "PPC, SEO and SEM",
      ],
      nickname: "growth",
    },
    {
      id: 88,
      headerText: "Software Maintenance",
      description: `
        Our on demand application and software management services help in
        maintaining your enterprise software and apps and we consistently
        increase the efficiency of your systems. With the increasing
        number of customers and teams, the expectations for value addition
        and better experience rise and requires more functionalities. We
        smartly manage your system upgrades, user interface changes,
        removal of outdated features, performance improvements and regular
        app update launches to boost your businesses growth and
        efficiency.
      `,
      subLinks: [
        "Adaptive Enhancements",
        "Software Release Management",
        "Server Migration and Updates",
        "Performance Improvements",
      ],
      nickname: "software",
    },
  ];

  const offerIconArr = [
    StartUpIcon,
    ProductDesignIcon,
    MobileDevIcon,
    WebDevIcon,
    EnterpriseIcon,
    NextGenIcon,
    GrowthIcon,
    MaintenanceIcon,
  ];

  const offerIcon = (iconIndex) => (
    <img
      src={offerIconArr[iconIndex]}
      className={"offerIcon"}
      alt=""
    />
  );

  const handleClickScroll = (scrollID) => {
    setActiveTab(scrollID);
    const element = document.getElementById(scrollID);
    if (element === "startup") {
      element.style.paddingTop = "25px";
    }
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    let options = {
      root: document.querySelector("#offerScrollMenuContainer"),
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting === true) {
          setActiveTab(entry.target.id);
        }
      });
    }, options);

    let st = document.querySelector("#startup");
    let p = document.querySelector("#product");
    let m = document.querySelector("#mobileDev");
    let w = document.querySelector("#webDev");
    let en = document.querySelector("#enterprise");
    let n = document.querySelector("#nextGen");
    let g = document.querySelector("#growth");
    let s = document.querySelector("#software");

    observer.observe(st);
    observer.observe(p);
    observer.observe(m);
    observer.observe(w);
    observer.observe(en);
    observer.observe(n);
    observer.observe(g);
    observer.observe(s);

    return () => {};
  }, []);

  const windwoWidth = useGetWindowWidth();

  return (
    <div className="offerContainer">
      <div className="offerIndicatorContainer">
        <div className="offerTabLine" />
        {offerTabArr.map((item) => {
          const { id, tabLabel, scrollID } = item;

          return (
            <div
              className="offerTabContainer"
              key={id}
              onClick={() => {
                handleClickScroll(scrollID);
              }}>
              <div
                className="offerTabCircle"
                style={{
                  background: activeTab === scrollID && "#0a5de9",
                  borderColor: activeTab === scrollID && "#0a5de9",
                }}
              />
              <div
                className="offerTabText"
                style={{
                  color: activeTab === scrollID && "#0a5de9",
                }}>
                {tabLabel}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="offerScrollMenuContainer"
        id="offerScrollMenuContainer">
        {offerContentArr.map((offer, index) => {
          const { id, headerText, description, subLinks, nickname } = offer;

          return (
            <div
              className="offerScrollMenuContent"
              key={id}
              id={nickname}>
              <div className="offerScrollMenuContentHeader">
                <div className="offerScrollMenuContentHeaderText">
                  <p
                    style={{
                      width:
                        windwoWidth.innerWidth < 840 &&
                        nickname === "enterprise" &&
                        "50vw",
                    }}>
                    {headerText}
                  </p>
                  <div className="offerScrollMenuContentHeaderLine" />
                </div>
                <div className="offerScrollMenuContentHeaderIcon">
                  {offerIcon(index)}
                </div>
              </div>
              <div
                className="offerScrollMenuContentDescription"
                style={{
                  marginTop:
                    windwoWidth > 840 && nickname === "product" ? "-50px" : "0",
                }}>
                <p className="offerDescription">{description}</p>
                <div className="offerScrollMenuContentGrid">
                  {subLinks.map((link) => {
                    return <p>{link}</p>;
                  })}
                </div>
                <p
                  className="offerScrollMenuContentMoreText"
                  style={{
                    top: "87%",
                    color: "#0a5de9",
                  }}>
                  Know more
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Offers;
