import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./App.css";
import Contact from "./components/ContactPart/contact";
import Header from "./components/Header";
import Footer from "./components/HomePart/Footer";
import SubFooter from "./components/HomePart/SubFooter";
import ScrollToTop from "./components/ScrollToTop";
import Company from "./components/company/company";
import Work from "./components/workpart/work";
import "./index.css";
import CompanyPolicy from "./pages/CompanyPolicy";
import HomePage from "./pages/home";
import Services from "./pages/services";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
  <div>
    <BrowserRouter>
      <ScrollToTop />
      <div className="main-wrap d-flex">
        <Switch>
          <Route
            exact
            path="/">
            <div>
              <Header />
              <HomePage />
            </div>
          </Route>
          <Route
            exact
            path="/home">
            <div>
              <Header />
              <HomePage />
            </div>
          </Route>
          <Route
            exact
            path="/services">
            <div>
              <Header />
              <Services />
            </div>
          </Route>
          <Route
            exact
            path="/work">
            <div>
              <Header />
              <Work />
              <div
                style={{
                  marginTop: "5%",
                }}>
                <SubFooter />
              </div>

              <Footer />
            </div>
          </Route>
          <Route
            exact
            path="/contact">
            <div>
              <Header />
              <Contact />
              <div
                style={{
                  marginTop: "5%",
                }}>
                <SubFooter />
              </div>

              <Footer />
            </div>
          </Route>
          <Route
            exact
            path="/company">
            <div>
              <Header />
              <Company />
              <div>
                <SubFooter />
              </div>
              <Footer />
            </div>
          </Route>
          <Route
            exact
            path="/companyPolicy">
            <div>
              <Header />
              <CompanyPolicy />
              <div>
                <SubFooter />
              </div>
              <Footer />
            </div>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);

registerServiceWorker();
