import companyPolicyStyle from "../assets/styles/companypart/companyPolicy.module.css";

const CompanyPolicy = () => {
  return (
    <div className={companyPolicyStyle.companyPolicyContainer}>
      <p className={companyPolicyStyle.headerText}>Company Policy</p>
      <p className={companyPolicyStyle.sectionHeader}>Introduction:</p>
      <p className={companyPolicyStyle.text}>
        At Troibits Infotech Private Limited, we are committed to delivering
        exceptional app development and IT solutions to our esteemed clients. To
        ensure a transparent, efficient, and legally compliant working
        relationship, we have established an extensive set of company policies.
        These policies outline the terms and conditions governing our services,
        providing clarity and protection for both our clients and our
        organization. By engaging our services, clients agree to adhere to these
        policies.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        1. Project Initiation and Scope:
      </p>
      <p className={companyPolicyStyle.text}>
        1.1. Prior to project initiation, a comprehensive scope of work, project
        objectives, and deliverables will be defined and agreed upon by both
        parties.
      </p>
      <p className={companyPolicyStyle.text}>
        1.2. Any modifications or changes to the project scope during
        development must be formally documented and approved by both the client
        and our project team.
      </p>
      <p className={companyPolicyStyle.text}>
        1.3. All projects will be executed in a phased manner, with regular
        milestone reviews to ensure alignment with client expectations.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>2. Confidentiality:</p>
      <p className={companyPolicyStyle.text}>
        2.1. We recognize the importance of maintaining confidentiality and
        safeguarding client information.
      </p>
      <p className={companyPolicyStyle.text}>
        2.2. All sensitive data, project details, and proprietary information
        shared with us will be treated with the highest level of
        confidentiality.
      </p>
      <p className={companyPolicyStyle.text}>
        2.3. We commit to not disclosing any client information to third parties
        without explicit written consent.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        3. Intellectual Property Rights:
      </p>
      <p className={companyPolicyStyle.text}>
        3.1. Clients retain complete ownership of their intellectual property
        rights.
      </p>
      <p className={companyPolicyStyle.text}>
        3.2. We do not claim ownership of any materials or content provided by
        the client for the project.
      </p>
      <p className={companyPolicyStyle.text}>
        3.3. Upon project completion and full payment, all rights and ownership
        of the deliverables will be transferred to the client.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>4. Payment Terms:</p>
      <p className={companyPolicyStyle.text}>
        4.1. A mutually agreed-upon payment schedule will be established before
        project commencement.
      </p>
      <p className={companyPolicyStyle.text}>
        4.2. Payment terms may include partial upfront payments, milestone-based
        payments, or a combination of both, depending on the project scope and
        duration.
      </p>
      <p className={companyPolicyStyle.text}>
        4.3. Timely payments are crucial for the seamless execution of the
        project. Delayed payments may lead to project delays or suspension.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        5. Quality Assurance and Testing:
      </p>
      <p className={companyPolicyStyle.text}>
        5.1. We prioritize quality assurance and undertake rigorous testing to
        deliver error-free products.
      </p>
      <p className={companyPolicyStyle.text}>
        5.2. Clients are encouraged to actively participate in the testing phase
        and provide timely feedback to ensure satisfactory outcomes.
      </p>
      <p className={companyPolicyStyle.text}>
        5.3. Our team will promptly address any reported issues or bugs after
        project completion.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        6. Support and Maintenance:
      </p>
      <p className={companyPolicyStyle.text}>
        6.1. Post-development support and maintenance options will be made
        available to clients as per their requirements.
      </p>
      <p className={companyPolicyStyle.text}>
        6.2. Clients can opt for ongoing support services or specific
        maintenance packages for continuous improvements and updates.
      </p>
      <p className={companyPolicyStyle.text}>
        6.3. The terms and conditions for support and maintenance services will
        be communicated separately.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        7. Cancellation and Refunds:
      </p>
      <p className={companyPolicyStyle.text}>
        7.1. In the event of project cancellation, any completed work up to that
        point will be billed at a pro-rata rate, based on the agreed-upon scope
        and payment terms.
      </p>
      <p className={companyPolicyStyle.text}>
        7.2. Refunds, if applicable, will be processed in accordance with the
        terms outlined in the signed agreement.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        8. Non-Disclosure Agreement (NDA):
      </p>
      <p className={companyPolicyStyle.text}>
        8.1. To provide additional security and protection, we are willing to
        sign a Non- Disclosure Agreement (NDA) with clients upon request.
      </p>
      <p className={companyPolicyStyle.text}>
        8.2. The NDA will outline the terms and obligations regarding the
        confidentiality of sensitive information.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>9. Communication:</p>
      <p className={companyPolicyStyle.text}>
        9.1. Effective communication is essential for the success of any
        project.
      </p>
      <p className={companyPolicyStyle.text}>
        9.2. Clients can reach us through designated communication channels
        during our standard working hours.
      </p>
      <p className={companyPolicyStyle.text}>
        9.3. Our team will respond to inquiries and concerns in a timely and
        professional manner.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>10. Non-Solicitation:</p>
      <p className={companyPolicyStyle.text}>
        10.1. Clients are requested not to solicit or directly offer employment
        to our team members during the course of the project and for a period
        after project completion.
      </p>
      <p className={companyPolicyStyle.text}>
        10.2. Any potential employment inquiries should be directed to our HR
        department.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        11. Termination of Services:
      </p>
      <p className={companyPolicyStyle.text}>
        11.1. In the event of breach of contract or violation of the agreed-upon
        terms by either party, termination of services may be considered.
      </p>
      <p className={companyPolicyStyle.text}>
        11.2. Termination will follow the procedures outlined in the signed
        agreement.
      </p>
      <p className={companyPolicyStyle.sectionHeader}>
        12. Updates to Company Policies:
      </p>
      <p className={companyPolicyStyle.text}>
        12.1. Company policies are subject to change without prior notice.
      </p>
      <p className={companyPolicyStyle.text}>
        12.2. Clients are advised to review our policies periodically for any
        updates
      </p>
      <p className={companyPolicyStyle.sectionHeader}>Conclusion:</p>
      <p
        className={companyPolicyStyle.text}
        style={{
          margin: 0,
        }}>
        We believe that adherence to these comprehensive company policies will
        foster a strong partnership and ensure a successful collaboration
        between Troibits Infotech Private Limited and our valued clients. These
        policies are designed to protect the interests of all stakeholders while
        maintaining the highest standard of professionalism and ethical conduct.
      </p>
      <p className={companyPolicyStyle.footerText}>
        For any inquiries or clarifications, please feel free to contact us
        using the provided communication channels.
      </p>
      <p className={companyPolicyStyle.footerText}>
        Thank you for entrusting us.
      </p>
      <p className={companyPolicyStyle.contactText}>
        Troibits Infotech Private Limited
      </p>
      <p>221 JMD Megapolis,</p>
      <p>Sohna Road,</p>
      <p>Sector 48,</p>
      <p>Gurugram, Haryana,</p>
      <p>India, 201002.</p>
    </div>
  );
};

export default CompanyPolicy;
