import React from "react";
import "../../assets/styles/services/drop-message.css";

function DropMessage({ title }) {
  return (
    <div className="drop-message-component">
      <div className="label d-none">Our Services</div>
      <div className="drop-title text-center">
        <div className="col">
          <h1>
            <strong>{title} </strong>
          </h1>
        </div>
      </div>
      <div
        className="box d-flex"
        style={{
          width: window.innerWidth < 650 ? "70%" : "",
        }}>
        <a
          href="/contact"
          className="variant">
          Drop us a Message
        </a>
      </div>
    </div>
  );
}

export default DropMessage;
