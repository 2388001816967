import React, { Component } from "react";
import Arrow from "../../assets/images/AssetsTroibits/left-arrow.png";
import Cube from "../../assets/images/homeimages/cube1X.png";
import "../../assets/styles/homepart/home.css";

class Home extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    const onScroll = (e) => {
      console.log("hello");
    };
    function scroll_to_next_component() {
      var h = window.innerHeight - 50;
      window.scrollTo(0, h);
    }
    return (
      <div onScroll={onScroll}>
        <div className="home">
          <div className="home-mobile">
            <img
              src={Cube}
              alt="cube"
              className="w-full mt-[13vh] mb-[10vh] scale-150"
              // style={{
              //   width: "150vw",
              //   marginTop: "5vh",
              //   marginLeft: "-35vw",
              // }}
            />
            <p
              style={{
                fontSize: "1.2em",
                fontWeight: "600",
              }}>
              We Make Your App Idea A
            </p>
            <p
              style={{
                fontSize: "3.5em",
              }}>
              SUCCESS
            </p>
            <div className="home-mobile-line" />
            <p
              className="home-subText"
              style={{
                width: "100%",
                textAlign: "center",
              }}>
         We developed one of India's first Cryptocurrency Wallets and the first Poultry E-Marketplace, Istanbul's Taxi Booking App, and the World's First Edtech App with a comprehensive 3D Game inside, featuring 70 levels and intricate challenges.
             </p>
          </div>
          <div className="home-left">
            <div className="home-heading">
              Mobile App Development
              <div>& Digital Transformation</div>
            </div>
            <div className="home-line"></div>
            <div className="home-subtext">
            Troibits is a leading-edge Technology Consultancy that transforms businesses by expediting Software Development, Digital Marketing and Growth Acceleration with latest technological innovations and Smart App Systems.
              </div>
            <a href="/contact" style={{ textDecoration: "none" }}>
              <div className="home-chat-btn">Let's chat</div>
            </a>
          </div>

          <div className="right-webgl home-part" id="rightWebGL">
            <div id="colorShadow">
              <img src={""} alt="" />
            </div>
            <div id="container">
              <img src={""} alt="" />
            </div>
          </div>
        </div>
        <div className="notice top-tri tri-part">
          <div className="label">Our Services</div>
          <img
            src={Arrow}
            alt="arrow"
            className="down-arrow"
            onClick={() => {
              scroll_to_next_component();
            }}
          />
        </div>
      </div>
    );
  }
}

export default Home;
