import { useEffect, useState } from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import Poultryfy from "../../assets/images/homeimages/Poultryfy.png";
import SSBWings from "../../assets/images/homeimages/ssbWings.png";
import ArrowLeft from "../../assets/images/leftarrow.png";
import Bit7pay from "../../assets/images/media_library/bit7pay.png";
import Skylark from "../../assets/images/media_library/skylark.png";
import ArrowRight from "../../assets/images/rightarrow.png";
import "../../assets/styles/homepart/appShowcase.css";
import useGetWindowWidth from "../../hooks/useWindowSize";

const AppShowcase = () => {
  const [activeTab, setActiveTab] = useState("poultry");
  const [activeTabCount, setActiveTabCount] = useState(0);

  // function scrollEvent() {
  //   let options = {
  //     root: document.querySelector("#appShowcaseScrollMenuContainer"),
  //     rootMargin: "0px",
  //     threshold: 0,
  //   };

  //   let observer = new IntersectionObserver(function (entries) {
  //     entries.forEach(function (entry) {
  //       if (entry.isIntersecting === true) {
  //         setActiveTab(entry.target.id);
  //         entry.target.scrollIntoView({
  //           behavior: "smooth",
  //           block: "nearest",
  //         });
  //         switch (entry.target.id) {
  //           case "poultry":
  //             setActiveTabCount(0);
  //             break;
  //           case "bit7pay":
  //             setActiveTabCount(1);
  //             break;
  //           case "skylar":
  //             setActiveTabCount(2);
  //             break;
  //           case "ssb":
  //             setActiveTabCount(3);
  //             break;
  //           default:
  //             setActiveTabCount(4);
  //             break;
  //         }
  //       }
  //     });
  //   }, options);

  //   let p = document.querySelector("#poultry");
  //   let b = document.querySelector("#bit7pay");
  //   let sk = document.querySelector("#skylar");
  //   let ss = document.querySelector("#ssb");

  //   observer.observe(p);
  //   observer.observe(b);
  //   observer.observe(sk);
  //   observer.observe(ss);
  // }

  const tabArr = ["poultry", "bit7pay", "skylar", "ssb"];

  const handlePrev = () => {
    if (activeTabCount > 0) {
      setActiveTabCount(activeTabCount - 1);
    }
  };

  const handleNext = () => {
    if (activeTabCount < 3) {
      setActiveTabCount(activeTabCount + 1);
    }
  };

  const Arrows = () => {
    return (
      <div className="appShowcaseArrow flex">
        <img
          src={ArrowLeft}
          alt=""
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            handlePrev();
          }}
        />
        <img
          src={ArrowRight}
          alt=""
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    );
  };

  const MobileArrows = () => {
    return (
      <div className="appShowcaseArrow">
        <img
          src={ArrowLeft}
          alt=""
          className="mobileArrow"
          onClick={() => {
            handlePrev();
          }}
        />
        <img
          src={ArrowRight}
          alt=""
          className="mobileArrow"
          onClick={() => {
            handleNext();
          }}
        />
      </div>
    );
  };

  const handleActiveTabOnTimer = () => {
    if (activeTabCount < 3) {
      setActiveTabCount(activeTabCount + 1);
    } else {
      setActiveTabCount(0);
    }
  };

  const handleActiveTabOnClick = (item, itemCount) => {
    setActiveTab(item);
    setActiveTabCount(itemCount);
  };

  const handleActiveTab = () => {
    setActiveTab(tabArr[activeTabCount]);
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      handleActiveTabOnTimer();
    }, 3000);
    return () => {
      clearInterval(intervalID);
    };
    // eslint-disable-next-line
  }, [activeTabCount]);

  useEffect(() => {
    handleActiveTab();
    // eslint-disable-next-line
  }, [activeTabCount]);

  const windowWidth = useGetWindowWidth();

  const element = document.querySelector(".appShowcaseMobileContainer");

  const mobileIndicatorScrollDetect = () => {
    const pIndicator = document.getElementById("pIndicator");
    const bIndicator = document.getElementById("bIndicator");
    const sIndicator = document.getElementById("sIndicator");
    const ssbIndicator = document.getElementById("ssbIndicator");

    if (element && window.scrollY > 2000 && window.scrollY < 2700)
      switch (activeTab) {
        case "poultry":
          pIndicator.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });

          break;
        case "bit7pay":
          bIndicator.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });

          break;
        case "skylar":
          sIndicator.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });

          break;
        case "ssb":
          ssbIndicator.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });

          break;

        default:
          pIndicator.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });

          break;
      }
  };

  useEffect(() => {
    windowWidth.innerWidth < 799 && mobileIndicatorScrollDetect();
    // eslint-disable-next-line
  }, [activeTab]);

  return windowWidth.innerWidth > 799 ? (
    <div className="appShowcaseContainer">
      {activeTab === "poultry" && (
        <div className="appShowcaseDescription">
          <p className="appShowcaseCategory">E-Commerce App</p>
          <p className="appShowcaseProductName">Poultryfy</p>
          <p className="appShowcaseSubDescription">
            India's First<br />Poultry E-Marketplace
          </p>
          <div className="appShowcasePlayLinks">
            <FaGooglePlay />
            <FaApple
              style={{
                fontSize: "25px",
              }}
            />
          </div>
          <Arrows />
        </div>
      )}
      {activeTab === "bit7pay" && (
        <div className="appShowcaseDescription">
          <p className="appShowcaseCategory">Cryptocurrency Wallet</p>
          <p className="appShowcaseProductName">Bit7Pay</p>
          <p className="appShowcaseSubDescription">
            Buy, Sell and Trade <br />100+ Cryptocurrencies
          </p>
          <div className="appShowcasePlayLinks">
            <FaGooglePlay />
            <FaApple
              style={{
                fontSize: "25px",
              }}
            />
          </div>
          <Arrows />
        </div>
      )}
      {activeTab === "skylar" && (
        <div className="appShowcaseDescription">
          <p className="appShowcaseCategory">Sales Automation</p>
          <p className="appShowcaseProductName">Skylark Feeds</p>
          <p className="appShowcaseSubDescription">
            Internal Sales App for one <br />of the Asia's Biggest Group
          </p>
          <div className="appShowcasePlayLinks">
            <FaGooglePlay />
            <FaApple
              style={{
                fontSize: "25px",
              }}
            />
          </div>
          <Arrows />
        </div>
      )}
      {activeTab === "ssb" && (
        <div className="appShowcaseDescription">
          <p className="appShowcaseCategory">Edtech App and Game</p>
          <p className="appShowcaseProductName">SSBWings</p>
          <p className="appShowcaseSubDescription">
            Subscribe to Courses, <br />Video Lessons and 3D Game
          </p>
          <div className="appShowcasePlayLinks">
            <FaGooglePlay />
            <FaApple
              style={{
                fontSize: "25px",
              }}
            />
          </div>
          <Arrows />
        </div>
      )}
      <div className="appShowcaseImage">
        <img
          src={
            activeTab === "poultry"
              ? Poultryfy
              : activeTab === "ssb"
              ? SSBWings
              : activeTab === "bit7pay"
              ? Bit7pay
              : activeTab === "skylar" && Skylark
          }
          alt=""
        />
      </div>
      <div className="appShowcaseWizard">
        <p>App Showcase</p>

        <div
          className="appShowcaseScrollMenuContainer"
          id="appShowcaseScrollMenuContainer">
          <div
            className={`appShowcaseScrollMenu ${
              activeTab === "poultry" && "active"
            }`}
            id="poultry"
            onClick={() => {
              handleActiveTabOnClick("poultry", 0);
            }}>
            Poultryfy
          </div>
          <div
            className={`appShowcaseScrollMenu ${
              activeTab === "bit7pay" && "active"
            }`}
            id="bit7pay"
            onClick={() => {
              handleActiveTabOnClick("bit7pay", 1);
            }}>
            Bit7Pay
          </div>
          <div
            className={`appShowcaseScrollMenu ${
              activeTab === "skylar" && "active"
            }`}
            id="skylar"
            onClick={() => {
              handleActiveTabOnClick("skylar", 2);
            }}>
            Skylark Feeds
          </div>
          <div
            className={`appShowcaseScrollMenu ${
              activeTab === "ssb" && "active"
            }`}
            id="ssb"
            onClick={() => {
              handleActiveTabOnClick("ssb", 3);
            }}>
            SSB Wings
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="appShowcaseMobileContainer">
      <p className="mobileHeader">App Showcase</p>
      <div className="appshowcaseTabContainer">
        <div
          className="appshowcaseTab"
          id="pIndicator"
          onClick={() => {
            handleActiveTabOnClick("poultry", 0);
          }}>
          Poultryfy
          {activeTab === "poultry" && <div className="activeDot" />}
        </div>
        <div
          className="appshowcaseTab"
          id="bIndicator"
          onClick={() => {
            handleActiveTabOnClick("bit7pay", 1);
          }}>
          Bit7Pay
          {activeTab === "bit7pay" && <div className="activeDot" />}
        </div>
        <div
          className="appshowcaseTab"
          id="sIndicator"
          onClick={() => {
            handleActiveTabOnClick("skylar", 2);
          }}>
          Skylark Feeds
          {activeTab === "skylar" && <div className="activeDot" />}
        </div>
        <div
          className="appshowcaseTab"
          id="ssbIndicator"
          onClick={() => {
            handleActiveTabOnClick("ssb", 3);
          }}>
          SSB Wings
          {activeTab === "ssb" && <div className="activeDot" />}
        </div>
      </div>
      <div className="appshowcaseImageContainer">
        <img
          src={
            activeTab === "poultry"
              ? Poultryfy
              : activeTab === "ssb"
              ? SSBWings
              : activeTab === "bit7pay"
              ? Bit7pay
              : activeTab === "skylar" && Skylark
          }
          className="appshowcaseImage"
          alt=""
        />
        <MobileArrows />
      </div>
      {activeTab === "poultry" && (
        <div className="appshowcaseMobileContent">
          <p className="contentHeader">Poultryfy</p>
          <p className="contentDescription">E-Commerce App</p>
          <p>India's First Poultry E-Marketplace</p>
        </div>
      )}
      {activeTab === "bit7pay" && (
        <div className="appshowcaseMobileContent">
          <p className="contentHeader">Bit7Pay</p>
          <p className="contentDescription">Cryptocurrency Wallet</p>
          <p>Buy, Sell and Trade 100+ Cryptocurrencies</p>
        </div>
      )}
      {activeTab === "skylar" && (
        <div className="appshowcaseMobileContent">
          <p className="contentHeader">Skylark Feeds</p>
          <p className="contentDescription">Sales Automation</p>
          <p>Internal Sales App for one of India's biggest group</p>
        </div>
      )}
      {activeTab === "ssb" && (
        <div className="appshowcaseMobileContent">
          <p className="contentHeader">SSBWings</p>
          <p className="contentDescription">Edtech App and Game</p>
          <p>Subscribe to Courses, Video Lessons and 3D Game</p>
        </div>
      )}
    </div>
  );
};

export default AppShowcase;
