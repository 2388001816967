import React, { Component } from "react";
import "../../assets/styles/homepart/efficient.css";
import "../../assets/styles/homepart/common.css";
import AnimatedNumber from "animated-number-react";
import ClouderaIcon from "../../assets/images/homeimages/cloudera.png";
import GoogleIcon from "../../assets/images/homeimages/google.png";
import MicrosoftIcon from "../../assets/images/homeimages/microsoft.png";
import OracleIcon from "../../assets/images/homeimages/oracle.png";
import SalesForceIcon from "../../assets/images/homeimages/salesforce.png";
import SocialIcon from "../../assets/images/homeimages/social.png";
import EfficientBG from "../../assets/images/homeimages/efficent-bg.jpg";
import DropMessage from "../Services/drop-message";

class Effcient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      long: 10,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.formatValue = this.formatValue.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    var b = document.getElementById("efficient_main");
    var longA = b.offsetTop - window.pageYOffset - 450;
    this.setState({ long: longA });
  }

  formatValue(value) {
    return value.toFixed(0);
  }

  render() {
    function calculateDaysElapsedSince(daysAgo) {
      const today = new Date();
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() - daysAgo);

      const timeDifference = today - targetDate;
      const daysElapsed = timeDifference / (1000 * 60 * 60 * 24);

      return Math.floor(daysElapsed);
    }

    const daysBefore = 3484;
    const daysElapsed = calculateDaysElapsedSince(daysBefore);

    return (
      <div id="efficient_main" onScroll={() => this.handleScroll.bind(this)}>
        <div className="efficient">
          <img src={EfficientBG} className="efficient-bg" alt="EfficientBG" />
          <div className="efficient-left">
            <div className="eff-line" />
            <div className="left-head" id="efficient-head">
              Our Partners
            </div>
            <div className="left-SubText flex">
              <img src={GoogleIcon} className="partnerLogo" alt="GoogleIcon" />
              Google
            </div>
            <div className="left-SubText flex">
              <img src={SocialIcon} className="partnerLogo" alt="SocialIcon" />
              AWS
            </div>
            <div className="left-SubText flex">
              <img src={OracleIcon} className="partnerLogo" alt="OracleIcon" />
              Oracle
            </div>
            <div className="left-SubText flex">
              <img
                src={SalesForceIcon}
                className="partnerLogo"
                alt="SalesForceIcon"
              />
              SalesForce
            </div>
            <div className="left-SubText flex">
              <img
                src={ClouderaIcon}
                className="partnerLogo"
                alt="ClouderaIcon"
              />
              Cloudera
            </div>
            <div className="left-SubText flex">
              <img
                src={MicrosoftIcon}
                className="partnerLogo"
                alt="MicrosoftIcon"
              />
              Microsoft
            </div>
          </div>
          {/* <div className="efficient-vrline" /> */}
          <div className="efficient-mid">
            <div className="mid-head">About Company</div>
            <div className="mid-mid">
              <h1>
                <strong>Efficient &</strong>{" "}
              </h1>
              <h1>
                <strong>Trustworthy</strong>
              </h1>
            </div>
            <div className="mid-subText">
              <p>
               At Troibits Infotech, our resolve to quality, efficiency, and transparency sets us apart. It's more than a promise — your project embarks on a journey ensuring exceptional results that surpass expectations.
                   </p>
            </div>
            <div>
              <a href="/contact">
                <button>Let's Chat</button>
              </a>
            </div>
          </div>
          <div className="efficient-end">
            <div>
              <div className="end-head">
                {this.state.long <= 0 && (
                  <strong>
                    <AnimatedNumber
                      value={daysElapsed}
                      formatValue={this.formatValue}
                      duration={1000}
                    />
                  </strong>
                )}
                {this.state.long > 0 && (
                  <strong>
                    <AnimatedNumber
                      value={0}
                      formatValue={this.formatValue}
                      duration={100}
                    />
                  </strong>
                )}
              </div>
              <div className="end-subText">
                <p>
                  days of <br />
                  dedicated coding.
                </p>
              </div>
            </div>
            <div>
              <div className="end-head">
                {this.state.long <= 0 && (
                  <strong>
                    <AnimatedNumber
                      value={158}
                      formatValue={this.formatValue}
                      duration={1000}
                    />
                  </strong>
                )}
                {this.state.long > 0 && (
                  <strong>
                    <AnimatedNumber
                      value={0}
                      formatValue={this.formatValue}
                      duration={1000}
                    />
                  </strong>
                )}
              </div>
              <div className="end-subText">
                clients worldwide, happy with our work.
              </div>
            </div>
          </div>
        </div>
        <div
          className="notice top-tri tri-part"
          style={{
            zIndex: 3,
            marginTop: "-50px",
          }}
        />
        <div className="dropMessage">
          <DropMessage title={"Liked Our Designs?"} />
        </div>
      </div>
    );
  }
}

export default Effcient;
