import React from "react";
import GoogleMapReact from "google-map-react";

import "../../assets/styles/homepart/map.css";

const Map = () => {
  const mapCenter = { lat: 28.42041618093861, lng: 77.03867288905892 };
  const mapZoom = 11;
  const Marker = ({ text }) => <div className="map-marker"></div>;

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBUJjh3VMC4hh_43PlVoYI3PRJ71nx-AVg" }}
        defaultCenter={mapCenter}
        defaultZoom={mapZoom}>
        <Marker
          lat={mapCenter.lat}
          lng={mapCenter.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
