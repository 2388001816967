import React, { useEffect } from "react";
import Footer from "../components/HomePart/Footer";
import SubFooter from "../components/HomePart/SubFooter";
import Offers from "../components/Services/Offers";
import Achievement from "../components/Services/achievement";
import DropMessage from "../components/Services/drop-message";
import HeaderServices from "../components/Services/header";
import Industry from "../components/Services/industry";
import Reason from "../components/Services/reason";
import Technology from "../components/Services/technology";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      className="page-wrapper"
      style={{ backgroundColor: "#0b0b0b" }}>
      <HeaderServices />
      {/* {isCarousel ? (
          <TabSlider />
        ) : (
         
        )} */}{" "}
      <div>{<Offers />}</div>
      {/* <Navbar
        isStatic={true}
        selected={true}
        isPositionFixed={true}
        // w={pull_data}
      /> */}
      <div className="industry">
        <Industry />
      </div>
      <div className="technology">
        <Technology isCarousel={false} />
      </div>
      <div className="achievement">
        <Achievement />
      </div>
      <div className="reason">
        <Reason />
      </div>
      <div className="drop-message">
        <DropMessage title={"Are you impressed?"} />
      </div>
      <div
        style={{
          marginTop: "5%",
        }}>
        <SubFooter />
      </div>
      <Footer />
    </div>
  );
};

export default Services;
