import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import enterprise from "../../assets/images/AssetsTroibits/enterprise (1).png";
import Growth from "../../assets/images/AssetsTroibits/growth Marketing (1).png";
import Startup from "../../assets/images/AssetsTroibits/launch.png";
import mobile from "../../assets/images/AssetsTroibits/Mobile Development (1).png";
import website from "../../assets/images/AssetsTroibits/web development (1).png";
import maintainance from "../../assets/images/AssetsTroibits/web-maintenance (1).png";
import nextgen from "../../assets/images/serivces/nextgen.PNG";
import productdesign from "../../assets/images/serivces/product.PNG";
import "../../assets/styles/homepart/common.css";
import "../../assets/styles/homepart/services.css";

const Services = () => {
  const history = useHistory();
  const goToPosts = (path) => {
    history.push(`/services?section=${path}`);
  };

  const [activeTab, setActiveTab] = useState("");

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="services">
      <div className="content-wrap">
        <div className="services-content subpart-content">
          <div className="main-title">What we do?</div>
          <div className="main-description">
            <h6 style={{ color: "white" }}>
              Your search for end-to-end technology partner ends here. We are <br />
               Top-Rated on Clutch, Google and the trusted choice of Fortune 500 Companies.
            </h6>
          </div>
          <div className="panels">
            <div className="panel-item-mobile">
              <div
                className={`panel-row-mobile ${
                  activeTab === "startup" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("startup");
                }}>
                <div className="panel-line" />
                <img
                  src={Startup}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>StartUp Acceleration</p>
                <p className="description-mobile">
                  Strategic Planning, Pitch Deck, B2B, B2C Plans, IT
                  Consultation and ROI Management
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "mobile" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("mobile");
                }}>
                <div className="panel-line" />
                <img
                  src={mobile}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Mobile App Development</p>
                <p className="description-mobile">
                  Android App Development, iOS App Development, Hybrid App
                  Development, Flutter and I.O.T
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "web" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("web");
                }}>
                <div className="panel-line" />
                <img
                  src={website}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Website Development</p>
                <p className="description-mobile">
                  React, Angular, WordPress, CMS and Admin Web Apps, Marketing
                  Ready Websites
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "enterprise" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("enterprise");
                }}>
                <div className="panel-line" />
                <img
                  src={enterprise}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Enterprise App Development</p>
                <p className="description-mobile">
                  Sales, Logistics, Inventory and Employee Management, CRM, CMS,
                  POS and SaaS
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "product" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("product");
                }}>
                <div className="panel-line" />
                <img
                  src={productdesign}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Product Design and Branding</p>
                <p className="description-mobile">
                  Branding, Corporate Identity, Marketing Media, Web and Mobile
                  App Design
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "nextGen" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("nextGen");
                }}>
                <div className="panel-line" />
                <img
                  src={nextgen}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Next Gen Technology</p>
                <p className="description-mobile">
                  AI, AR, VR, ML, Connected Hardware, I.O.T, Automation and
                  Drone Tech
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "growth" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("growth");
                }}>
                <div className="panel-line" />
                <img
                  src={Growth}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Growth Marketing</p>
                <p className="description-mobile">
                  Digital Marketing Campaigns, Media and Video Production,
                  Social Media Marketing and PR
                </p>
              </div>
              <div
                className={`panel-row-mobile ${
                  activeTab === "support" && "active"
                }`}
                onClick={() => {
                  handleActiveTab("support");
                }}>
                <div className="panel-line" />
                <img
                  src={maintainance}
                  alt="startup"
                  className="panel-img-mobile"
                />
                <p>Maintenance and support</p>
                <p className="description-mobile">
                  Adaptive Enhancements, Updates, Features and Release
                  Management, Server Migration
                </p>
              </div>
            </div>
            <div className="panel-row">
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-startup-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={Startup}
                    className="panel-img"
                    alt="panel"
                  />
                </div>
                <div className="panel-title">
                  <div>StartUp</div> <div>Acceleration</div>
                </div>
                <div className="description">
                  Strategic Planning, Pitch Deck, B2B, B2C Plans, IT
                  Consultation and ROI Management
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-mobile-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={mobile}
                    className="panel-img"
                    alt="panel"
                  />
                </div>
                <div className="panel-title">
                  <div>Mobile App</div>
                  <div>Development</div>
                </div>
                <div className="description">
                  Android App, iOS App Development, Hybrid App Development,
                  Flutter and IoT
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-web-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={website}
                    className="panel-img"
                    alt="panel"
                  />
                </div>
                <div className="panel-title">
                  <div>Website</div> <div>Development</div>
                  {/*meny mation */}
                </div>
                <div className="description">
                  React, Angular, WordPress, CMS and Admin Web Apps, Marketing
                  Ready Websites
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-enterprise-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={enterprise}
                    className="panel-img"
                    alt="panel"
                  />
                </div>
                <div
                  className="panel-title"
                  id="enterprise">
                  <div>Enterprise App</div> <div>Development</div>
                </div>
                <div className="description">
                  Sales, Logistics, Inventory and Employee Management, CRM, CMS,
                  POS and SaaS
                </div>
              </div>
            </div>
            <div className="panel-row">
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-mobile-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={productdesign}
                    className="panel-img"
                    id="product-img"
                    alt="product"
                  />
                </div>
                <div className="panel-title">
                  <div>Product Design</div> <div>and Branding</div>
                </div>
                <div className="description">
                  Branding, Corporate Identity, Marketing Media, Web and Mobile
                  App Design
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-iot-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={nextgen}
                    className="panel-img"
                    id="nextgen-img"
                    alt="panel"
                  />
                </div>
                <div
                  className="panel-title"
                  id="nextgen">
                  <div>Next Gen</div> <div>Technology</div>
                </div>
                <div className="description">
                  AI, AR, VR, ML, Connected Hardware, I.O.T, Automation and
                  Drone Tech
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-growth-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={Growth}
                    className="panel-img"
                    alt="growth"
                  />
                </div>
                <div
                  className="panel-title"
                  id="growth">
                  <div>Growth</div> <div>Marketing</div>
                </div>
                <div className="description">
                  Digital Marketing, Media and Video Production, Social Media
                  Marketing and PR
                </div>
              </div>
              <div
                className="panel-item"
                onClick={() => {
                  goToPosts("nav-software-tab");
                }}>
                <div className="panel-logo">
                  <img
                    src={maintainance}
                    className="panel-img"
                    alt="panel"
                  />
                </div>
                <div
                  className="panel-title"
                  id="maintainance">
                  <div>Maintainance</div> <div>and support</div>
                </div>
                <div className="description">
                  Adaptive Enhancements, Updates, Features and Release
                  Management, Server Migration
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
