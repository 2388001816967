import "../../assets/styles/homepart/subFooter.css";

const SubFooter = () => {
  return (
    <div className="subFooterContainer">
      <div className="subFooterContent">
        <p className="subFooterHeader">Mobile</p>
        <p>
          <a href="/services">Android Apps</a>
        </p>
        <p>
          <a href="/services">iPhone Apps</a>
        </p>
        <p>
          <a href="/services">Hybrid Apps</a>
        </p>
        <p>
          <a href="/services">Swift</a>
        </p>
        <p>
          <a href="/services">Kotlin</a>
        </p>
      </div>
      <div className="subFooterContent">
        <p className="subFooterHeader">Web</p>
        <p>
          <a href="/services">React Js</a>
        </p>
        <p>
          <a href="/services">Angular Js</a>
        </p>
        <p>
          <a href="/services">Vue Js</a>
        </p>
        <p>
          <a href="/services">Java</a>
        </p>
        <p>
          <a href="/services">WordPress</a>
        </p>
      </div>
      <div className="subFooterContent">
        <p className="subFooterHeader">Game</p>
        <p>
          <a href="/services">Unity 3D</a>
        </p>
        <p>
          <a href="/services">Unreal Engine</a>
        </p>
        <p>
          <a href="/services">Augmented Reality</a>
        </p>
        <p>
          <a href="/services">Virtual Reality</a>
        </p>
        <p>
          <a href="/services">Metaverse</a>
        </p>
      </div>
      <div className="subFooterContent">
        <p className="subFooterHeader">IOT</p>
        <p>
          <a href="/services">Automation</a>
        </p>
        <p>
          <a href="/services">Connected Hardware</a>
        </p>
        <p>
          <a href="/services">AI and ML</a>
        </p>
        <p>
          <a href="/services">Robotics</a>
        </p>
        <p>
          <a href="/services">Drone Tech</a>
        </p>
      </div>
      <div className="subFooterContent">
        <p className="subFooterHeader">Quicklinks</p>
        <p>
          <a href="/services">Services</a>
        </p>
        <p>
          <a href="/company">About Us</a>
        </p>
        <p>
          <a href="/work">Our Work</a>
        </p>
        <p>
          <a href="/companyPolicy">Company Policy</a>
        </p>
        <p>
          <a href="/contact">Contact</a>
        </p>
      </div>
    </div>
  );
};

export default SubFooter;
