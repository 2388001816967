import jQuery from "jquery";
import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Mockup from "../../assets/images/Mockup.svg";
import glaucusTesty from "../../assets/images/homeimages/Glaucus_logo.png";
import Lorenzo from "../../assets/images/homeimages/Lorenzo.png";
import glaucusClient from "../../assets/images/homeimages/Mandeep.jpg";
import bitpayClient from "../../assets/images/homeimages/bit7pay-client.jpg";
import bitpayTesty from "../../assets/images/homeimages/bit7pay-testy.png";
import Yolo from "../../assets/images/homeimages/yolo.png";
import ArrowLeft from "../../assets/images/leftarrow.png";
import glaucus from "../../assets/images/media_library/11_Laptop_Mockup-min-min.png";
import yolo from "../../assets/images/media_library/152-min.png";
import group1 from "../../assets/images/media_library/groupasda.png";
import ArrowRight from "../../assets/images/rightarrow.png";
import "../../assets/styles/services/achievement.css";

const Achievement = () => {
  return (
    <div className="achievement-challenges">
      <div className="achievement-titl text-center">
        <div className="">
          <h1>
            <strong>Achievements and Challenges</strong>
          </h1>
        </div>
      </div>
      <div>
        <AchievementItems />
      </div>

      <div className="project">
        <h2>Advancing beyond your Experience</h2>
        <h4>
          Smart UI and intuitive design is our forte. We customise it for you
          after proper ideation, research, planning and create something on a
          blank, canvas which stands out, is minimal and uniquely crafted for
          your business.
        </h4>
        <div className="explore_more">
          <NavLink
            to="/work"
            className="variant">
            Explore More Projects
          </NavLink>
        </div>
      </div>
      <div className="client">
        <div className="client-title text-center">
          <div className="col">
            <strong>
              <h1>Our Clients Love Us</h1>
            </strong>
          </div>
        </div>
        <ClientItems />
      </div>
      <div className="footer-logo-ach">
        <div className="image">
          <img
            src={group1}
            alt="group1"
          />
        </div>
      </div>
    </div>
  );
};

class ClientItems extends React.Component {
  constructor(props) {
    super(props);
    this.selAppItem = "yolo";
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.Arrows = this.Arrows.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  Arrows() {
    return (
      <div
        style={{
          position: "absolute",
          top: "35%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 2% 0 2%",
          zIndex: 4,
        }}>
        <img
          src={ArrowLeft}
          onClick={() => this.previous()}
          style={{
            cursor: "pointer",
            width: window.innerWidth > 800 ? "35px" : "30px",
            height: window.innerWidth > 800 ? "35px" : "30px",
          }}
          alt="ArrowLeft"
        />
        <img
          src={ArrowRight}
          onClick={() => {
            this.next();
          }}
          alt="ArrowRight"
          style={{
            cursor: "pointer",
            width: window.innerWidth > 800 ? "35px" : "30px",
            height: window.innerWidth > 800 ? "35px" : "30px",
          }}
        />
      </div>
    );
  }
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnMouseEnter: false,
    };

    return (
      <div
        style={{
          position: "relative",
        }}>
        <this.Arrows />
        <Slider
          {...settings}
          ref={(c) => (this.slider = c)}>
          <div
            id="carouselExampleControls"
            className="carousel  carousel-client slide"
            data-ride="carousel">
            <div
              className=" inner-client main-div"
              id="client1">
              <div className="d-flex client-item-box active">
                <div className="header-client-box d-flex">
                  <div className="profile-client d-flex">
                    <img
                      src={Lorenzo}
                      className="profile-img"
                      alt="Lorenzo"
                    />
                    <div className="profile-info">
                      <h5>Lorenzo Zamorano</h5>
                      <h6 className="text-primary">Manager at Yolo</h6>
                    </div>
                  </div>
                  <div className="company-logo">
                    <img
                      src={Yolo}
                      className="testy"
                      alt="yolo"
                    />
                  </div>
                </div>
                <div className="body-client-box d-flex">
                  <div className="client-word">
                    <p>
                      &#10077; Tarun made pixel perfect designs for Yolo Cabs
                      &#10078;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="carouselExampleControls"
            className="carousel  carousel-client slide"
            data-ride="carousel">
            <div
              className=" inner-client"
              id="client2">
              <div className="d-flex client-item-box active">
                <div className="header-client-box d-flex">
                  <div className="profile-client d-flex">
                    <img
                      src={bitpayClient}
                      className="profile-img"
                      alt="bitpayClient"
                    />
                    <div className="profile-info">
                      <h5>Anurag Chandel</h5>
                      <h6 className="text-primary">Founder at Bit7pay</h6>
                    </div>
                  </div>
                  <div
                    className="company-logo"
                    style={{
                      marginRight: window.innerWidth > 500 ? "50px" : "0",
                    }}>
                    <img
                      src={bitpayTesty}
                      className="testy"
                      alt="bitpayTesty"
                      style={{
                        scale: window.innerWidth > 500 ? "2" : "1",
                        filter: "grayscale(100%)",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
                <div className="body-client-box d-flex">
                  <div className="client-word">
                    <p
                      style={{
                        fontSize: window.innerWidth < 550 && "0.5em",
                      }}>
                      &#10077; We have been working with Troibits for more than
                      4 years and customers easily trade on our Cryptocurrency
                      Apps everyday. &#10078;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="carouselExampleControls"
            className="carousel  carousel-client slide"
            data-ride="carousel">
            <div
              className="inner-client"
              id="client3">
              <div className="d-flex client-item-box active">
                <div className="header-client-box d-flex">
                  <div className="profile-client d-flex">
                    <img
                      src={glaucusClient}
                      className="profile-img"
                      alt="glaucusClient"
                    />
                    <div className="profile-info">
                      <h5>Mandeep Kanwal</h5>
                      <h6
                        className="text-primary"
                        style={{
                          width: window.innerWidth < 450 && "50%",
                        }}>
                        Solution Architect at Glaucus Logistics
                      </h6>
                    </div>
                  </div>
                  <div
                    className="company-logo"
                    style={{
                      marginRight: window.innerWidth > 500 ? "50px" : "0",
                    }}>
                    <img
                      src={glaucusTesty}
                      className="testy"
                      style={{
                        scale: window.innerWidth > 500 ? "2" : "1",
                        filter: "grayscale(100%)",
                        objectFit: "contain",
                      }}
                      alt="glaucusTesty"
                    />
                  </div>
                </div>
                <div className="body-client-box d-flex">
                  <div className="client-word">
                    <p>
                      &#10077; We really liked our Warehouse Management System
                      design and Troibits implemented every feature perfectly.
                      &#10078;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}

class AchievementItems extends React.Component {
  constructor(props) {
    super(props);
    this.selAppItem = "yolo";
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.Arrows = this.Arrows.bind(this);
    this.clickApp = this.clickApp.bind(this);
  }
  next() {
    this.clickApp("next");
    this.slider.slickNext();
  }
  previous() {
    this.clickApp("prev");
    this.slider.slickPrev();
  }
  Arrows() {
    return (
      <div
        style={{
          position: "absolute",
          top: "20%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 2% 0 2%",
        }}>
        <img
          src={ArrowLeft}
          onClick={() => this.previous()}
          style={{
            width: "35px",
            height: "35px",
          }}
          alt="ArrowLeft"
        />
        <img
          src={ArrowRight}
          onClick={() => {
            this.next();
          }}
          style={{
            width: "35px",
            height: "35px",
          }}
          alt="ArrowRight"
        />
      </div>
    );
  }
  clickApp(str) {
    if (str === "next") {
      if (this.selAppItem === "yolo") {
        str = "third";
      }

      if (this.selAppItem === "third") {
        str = "first";
      }
      if (this.selAppItem === "first") {
        str = "yolo";
      }
    }
    if (str === "prev") {
      if (this.selAppItem === "yolo") {
        str = "first";
      }

      if (this.selAppItem === "third") {
        str = "yolo";
      }
      if (this.selAppItem === "first") {
        str = "third";
      }
    }
    this.selAppItem = str;
    console.log(this.selAppItem);
    jQuery(".app-content-item").removeClass("active");
    jQuery(".app-content-" + str).addClass("active");
    if (this.selAppItem === "yolo") {
    } else if (this.selAppItem === "first") {
      jQuery("#yolo");
      jQuery("#third-app");
      jQuery("#first-app");
    } else if (this.selAppItem === "third") {
      jQuery("#third-app");
      jQuery("#first-app");
      jQuery("#yolo");
    }
  }
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnMouseEnter: false,
    };

    setTimeout(() => {
      this.clickApp("yolo");
    }, 0);
    return (
      <div>
        <Slider
          ref={(c) => (this.slider = c)}
          {...settings}
          className="achieveSlide">
          <div className="item-main">
            <div
              className="mid"
              id="bit7pay">
              <img
                src={Mockup}
                alt="Mockup"
              />
              <div>
                <div
                  className="mid-title"
                  id="bit7payText">
                  Bit7pay
                </div>
                <div className="sub-text">
                  <div
                    className="mid-sub"
                    style={{ color: "#0072ff" }}>
                    Cryptocurrency Wallet & Exchange
                  </div>
                  <p>
                    Bit7 pay is Cryptocurrency wallet & Exchange to store Buy
                    and sell Bitcoin,Ethereum,Ripple, Bitcoin Gold and keep all
                    other cryptocurrencies safe.Download Bit7pay on your mobile
                    and get your account verified within hours to make your
                    first investment. We made an easy to use inteface that
                    allows users to quickly make the transactions with no
                    latency. Troibits helped Bit7pay to run it's cloud servers
                    on the latest hardware available today
                  </p>
                  <div style={{ color: "#0072ff" }}>Android | iOS | Web</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-main">
            <div
              className="mid"
              id="yolo">
              <img
                src={yolo}
                id="yolo-img"
                alt="yolo"
              />
              <div>
                <div className="mid-title yoloText">Yolo</div>
                <div className="sub-text">
                  <div
                    className="mid-sub"
                    style={{ color: "#0072ff" }}>
                    Taxi Booking platform
                  </div>
                  <p>
                    Yolo cabs is the smartest way to get around. One tap and a
                    car comes directly to you. Your dirver knows excatly where
                    to go. And you can pay either cash or card. Daily commute.
                    Errand across town. Early morning flight. Late night drinks.
                    wherever you're headed, Count on YoloCabs for a ride -no
                    reservations needed.You can always request everyday cars at
                    everyday prices. But sometimes you need a bit more space. Or
                    you want to go big on style. With YoloCabs, the choice is
                    yours
                  </p>
                  <div style={{ color: "#0072ff" }}>Android | iOS</div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-main">
            <div
              className="mid"
              id="glaucus">
              <img
                src={glaucus}
                alt="glaucus"
              />
              <div>
                <div className="mid-title yoloText">Glaucus Logistics</div>
                <div className="sub-text">
                  <div
                    className="mid-sub"
                    style={{ color: "#0072ff" }}>
                    Warehouse Management System
                  </div>
                  <p>
                    We made a perfect design and UI in Angular Js for Galucus
                    Logistics.They have been founded by a team with exhausitve
                    experience of supple chain solutions,advisory,Warehouse
                    design and technology product development. They are the
                    preffered supply chain management partner for small and
                    mid-sized companies focusing on trading, retail & wholesale
                    distribution allowing businesses to effectively compete with
                    their larger competitors on effencies and effectiveness.
                  </p>
                  <div style={{ color: "#0072ff" }}>Mac | windows | Web</div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <this.Arrows />
      </div>
    );
  }
}
export default Achievement;
