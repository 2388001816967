import React from "react";
import "../../assets/styles/services/header.css";

const HeaderServices = () => (
  <div className="head d-flex text-center" id="headerServices">
    <div className="col1">
      <h1>
        <strong>
          Your End-to-End{" "}
          <span className="text-primary">Technology Partner</span>
        </strong>
      </h1>
    </div>
    <div className="col1">
      <h3>
        We are your extended team who keeps digitizing your business and moves
        the software development and marketing process quickly to exponentially
        grow your business by introducing latest innovations, reducing
        management time and increasing efficiency.
      </h3>
    </div>
  </div>
);

export default HeaderServices;
