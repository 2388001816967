import emailjs from "@emailjs/browser";
import { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Arrow from "../../assets/images/homeimages/service/arrow-down.png";
import ArrowRight from "../../assets/images/homeimages/service/arrow-right.png";

const ContactPage = () => {
  const [data, setData] = useState({});

  const handleInput = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, phoneSkype, message, subject } = data;
    if (!name || !email || !phoneSkype || !message || !subject) {
      notify("Fill all fields", {
        progressStyle: { background: "red" },
      });
    } else {
      sendMail();
      setData({
        name: "",
        email: "",
        phoneSkype: "",
        message: "",
        subject: "",
      });
    }
  };

  const notify = (text, options) => toast(text, options);

  const sendMail = async () => {
    const { name, email, phoneSkype, message, subject } = data;

    const emailMessage = `
      Phone or Skpye: ${phoneSkype},
      Message: ${message},
      Subject: ${subject}
    `;
    emailjs
      .send(
        "service_apacmuj",
        "template_jga1g2r",
        {
          from_name: name,
          to_name: "Troibits Infotech",
          from_email: email,
          to_email: "contact@troibits.com",
          message: emailMessage,
        },
        "aEB3tSB1okYO5_Xlz"
      )
      .then(
        () => {
          notify("Thank you. We will get back to you as soon as possible.", {
            progressStyle: { background: "green" },
          });
        },
        (error) => {
          console.error(error);
          notify("Ahh, something went wrong. Please try again.", {
            progressStyle: { background: "red" },
          });
        }
      );
  };
  return (
    <div className="w-full bg-[#0b0b0b] px-4 py-20 flex flex-col lg:flex-row lg:justify-between lg:space-x-5">
      <ToastContainer theme="dark" />
      <div className="flex items-center flex-col lg:items-start lg:w-3/5 xl:w-3/5 lg:px-5 xl:pr-[10%] 2xl:w-1/2 3xl:pl-14 3xl:w-[60%]">
        <p className="text-white text-4xl">Contact Us</p>
        <div className="w-full lg:pr-10">
          <div className="lg:grid lg:grid-cols-2 2xl:pr-10">
            <div className="flex flex-col items-center lg:items-start">
              <p className="text-[#808080] mt-3">Address</p>
              <div className="mx-auto">
                <p className="text-lg flex lg:hidden text-center">
                  221 JMD Megapolis, Sohna Road Highway, Sector 48 Gurugram,
                  Haryana India, 122011
                </p>
                <p className="hidden lg:flex lg:text-left">
                  221 JMD Megapolis, Sohna Road Highway, Sector 48 Gurugram,
                  Haryana India, 122011
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start lg:ml-auto lg:-mt-2 3xl:ml-40">
              <p className="text-[#808080] my-4 sm:my-8">Social Media</p>
              <div className="flex space-x-10">
                <FaInstagram size={25} />
                <FaFacebook size={25} />
                <FaLinkedin size={25} />
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <p className="text-[#808080] mt-5 mb-3 sm:mt-8 sm:mb-5">Email</p>
              <p className="text-lg">contact@troibits.com</p>
            </div>
            <div className="flex flex-col items-center lg:mt-8 lg:items-start lg:ml-auto 3xl:ml-40">
              <p className="text-[#808080] mt-3 sm:mt-8">Phone</p>
              <p className="text-lg">+91 989 950 0873</p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex lg:mt-12 min-[1074px]:mt-[4.3rem] xl:mt-32 3xl:mt-36 space-x-5 h-6">
          <p>We are on the map</p>
          <a href="#map">
            <img src={Arrow} className="w-4 h-4 animate-bounce mt-1" alt="" />
          </a>
        </div>
      </div>

      <form
        className="w-full flex items-center justify-center flex-col pt-5 px-1 space-y-5 lg:w-2/5 lg:-mt-24 xl:w-2/5 xl:pr-[5%] 2xl:w-1/2 3xl:w-[35%] 3xl:ml-auto"
        onSubmit={(e) => {
          handleSubmit(e);
        }}>
        <p className="text-xl font-semibold my-5 lg:text-start">Get in touch</p>
        <div className="w-full flex flex-col space-y-5 sm:grid grid-cols-2">
          <input
            type="text"
            placeholder="Name"
            className="w-full inpuText sm:w-[90%] bg-transparent outline-none text-[#808080] border-b border-[#808080] py-3 text-lg"
            name="name"
            onChange={(e) => {
              handleInput(e);
            }}
            value={data.name}
          />
          <input
            type="text"
            placeholder="Email"
            className="w-full inpuText sm:w-[90%] sm:ml-auto bg-transparent outline-none text-[#808080] border-b border-[#808080] py-3 text-lg"
            name="email"
            onChange={(e) => {
              handleInput(e);
            }}
            value={data.email}
          />
          <input
            type="text"
            placeholder="Phone or Skype"
            className="w-full inpuText sm:w-[90%] bg-transparent outline-none text-[#808080] border-b border-[#808080] py-3 text-lg"
            name="phoneSkype"
            onChange={(e) => {
              handleInput(e);
            }}
            value={data.phoneSkype}
          />
          <input
            type="text"
            placeholder="Subject"
            className="w-full inpuText sm:w-[90%] sm:ml-auto bg-transparent outline-none text-[#808080] border-b border-[#808080] py-3 text-lg"
            name="subject"
            onChange={(e) => {
              handleInput(e);
            }}
            value={data.subject}
          />
        </div>

        <input
          type="text"
          placeholder="Message"
          className="w-full inpuText bg-transparent outline-none text-[#808080] border-b border-[#808080] py-3 text-lg"
          name="message"
          onChange={(e) => {
            handleInput(e);
          }}
          value={data.message}
        />

        <div className="w-full flex items-center justify-center xl:pt-10">
          <button
            type="submit"
            className="w-1/2 flex items-center justify-center sm:w-1/4 mt-5 mb-2 bg-[#0a5de9] px-3 py-2 text-lg rounded-md lg:w-2/5 2xl:w-1/4 lg:ml-auto">
            Submit
            <img src={ArrowRight} className="w-5 h-5 ml-3" alt="" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactPage;
